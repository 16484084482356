












import { Component, Vue, Watch } from "vue-property-decorator";
import Cookies from "js-cookie";
import isPlainObject from "lodash/isPlainObject";
import isEmpty from "lodash/isEmpty";

@Component
export default class ExternalLogin extends Vue {
  private accessToken: string = "";
  private refreshToken: string = "";

  mounted() {
    this.accessToken = Cookies.get("access_token") || "";
    this.refreshToken = Cookies.get("refresh_token") || "";
  }

  get tokens() {
    const nonEmptyString = (x: any) =>
      typeof x === "string" && Boolean(x.trim().length) && x.trim();

    const accessToken = nonEmptyString(this.accessToken);
    const refreshToken = nonEmptyString(this.refreshToken);

    return accessToken && refreshToken ? { accessToken, refreshToken } : null;
  }

  @Watch("tokens", { immediate: true })
  private authentication() {
    if (isPlainObject(this.tokens) && !isEmpty(this.tokens)) {
      this.$store
        .dispatch("auth/externalLogin", this.tokens)
        .then(() => {
          this.$router.push({ name: "home" }).catch((error) => {
            console.error(
              "error occured on homepage redirect after successful external auth",
              error
            );
          });
        })
        .catch((error) => {
          this.$store.dispatch("auth/logout");
        });
    } else {
      console.warn(
        "tokens not valid (external login) ?",
        new Date().toISOString(),
        this.tokens,
        this.accessToken,
        this.refreshToken,
        Cookies.get()
      );
    }
  }
}
